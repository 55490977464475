import React, { Component } from 'react';
import { Link } from "gatsby";

import PrimaryLayout from '../layouts/PrimaryLayout';

class Page404 extends Component {
    render() {
        const page = this.props.data.wordpressPage;
        return (
            <PrimaryLayout>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 page404">
                            <div dangerouslySetInnerHTML={{__html: page.content}} />
                            <Link to="/" className="btn">Go Back</Link>
                        </div>    
                    </div>        
                </div>
            </PrimaryLayout>
        );
    }
}

export default Page404;

export const query = graphql`
{
    wordpressPage(wordpress_id: {eq: 3202}) {
        content
    }
}
`